@ColorLightGrey: #8C8C8C;
@ColorMiddleGrey: #575757;
@ColorDarkGrey: rgba(142,142,147,.12);
@ColorBlack: #000;
@ColorBlackO1: rgba(0,0,0,.1);
@ColorBlackO3: rgba(0,0,0,.3);
@ColorC9: #C9C9C9;
@ColorF8: #F8F8F8;
@ColorE8: #E8E8E8;
@ColorRed:#f23030;
@ColorYellow:#FFAA00;
@ColorBlue:#2979FF;
@ColorLightYellow:#42351a;
@ColorWhite: #fff;
@ColorA: #aaa;
@YellowGradient: linear-gradient(180deg, #FFB92E 0%, #FFDD5B 100%);
@BlueGradient: linear-gradient(180deg, #2979FF 0%, #53B1FF 100%);
@ColorItemBg: #F1F1F2;

@font32: 3.2rem;
@font30: 3rem;
@font24: 2.4rem;
@font22: 2.2rem;
@fontSupLarge: 2rem;
@fontLarge: 1.8rem;
@fontTitle: 1.6rem;
@fontContent: 1.4rem;
@fontSubContent: 1.2rem;
@fontMin: 1rem;
@font15: 1.5rem;

@PageTitleHeight: 4.8rem;
@PageContentHeight: calc(100% - 4.8rem);

@borderPadding: 2rem;

.commonBorderPadding {
  padding: 0 2rem;
}
.commonLineMargin {
  margin: 2rem 0;
}

.commonLine {
  border-bottom: 1px solid @ColorBlackO3;
}

.commonLeftPose {
  width: 100%;
  border-radius: 1rem;
  border-left: 1.2rem solid @ColorYellow;
  background: @ColorDarkGrey;
  margin: 1rem 0;
  padding: .5rem;
  box-sizing: border-box;
}

.commonThemeBtn {
  height: 4.4rem;
  width: 98%;
  color: @ColorWhite !important;
  background: @ColorYellow !important;
  font-size: @fontLarge;
}
.commonScenodPageBlock {
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 4.8rem);
}

.bg-theme-doctor {
  background-color: @ColorYellow;
}
.bg-theme-patient {
  background-color: @ColorBlue;
}
.tx-color-doctor {
  color: @ColorYellow;
}
.tx-color-patient {
  color: @ColorBlue;
}
.border-color-doctor {
  border: 1px solid @ColorYellow;
}

.error-tx-color {
  color: @ColorRed;
}

.tab-txt-margin-top {
  margin-top: .5rem;
}
/* 样式重写 */
.adm-input .adm-input-element{
  border-radius: .4rem;
  padding: .5rem;
  border: 1px solid @ColorBlackO3;
}
// 搜索框隐藏border
.adm-search-bar {
  .adm-input .adm-input-element{
    border: none;
  }
}
.adm-input-disabled {
  opacity: 1;
} 

/*
* UI 设计规范样式 -fontSize  lineHeight and paragraph spacing for body is 1.4*fontSize
*/
@BodyMulti: 1.4;
.BodyFontAndLineHeightCommon(@fontSize) {
  font-size: @fontSize;
  line-height: @BodyMulti * @fontSize;
}
@LargeText: 20px;
@MediumText: 18px;
@NormalText: 16px;
@SmallText: 15px;
@SmallLessText: 13px;
@MiniText: 12px;

// ICON style
.IconCommonStyle {
  width: 24px;
  height: 24px;
}

/*
*normal button rule: padding left and right 5 * fontSize; top and bottom 1 * fontSize
*full-width button rule: lef and right determined by device; top and bottom 1 * fontSize
*/
.ButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.SecondaryButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  background: @ColorWhite;
  color: @ThemeColor;
  border-radius: 8px;
  border: 1px solid @ThemeColor;
}
.NormalLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .ButtonStyle(@LargeText, @ThemeColor);
}
.SecondaryLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .SecondaryButtonStyle(@LargeText, @ThemeColor);
}
.NormalMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .ButtonStyle(@MediumText, @ThemeColor);
}
.SecondaryMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .SecondaryButtonStyle(@MediumText, @ThemeColor);
}
.NormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .ButtonStyle(@NormalText, @ThemeColor);
}
.SecondaryNormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .SecondaryButtonStyle(@NormalText, @ThemeColor);
}

// full-width button
.FullWidthButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  padding: @fontSize*0.8 0;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.FullWidthSmallButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 4px;
}

.NormalFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .FullWidthButtonStyle(@NormalText, @ThemeColor);
}
.MiniFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MiniText);
  .FullWidthSmallButtonStyle(@MiniText, @ThemeColor);
}

.commonPadding {
  padding: 1.5rem 1rem;
}
.doctor-block {
    display: flex;
    flex-direction: column;
    min-height: @PageContentHeight;

    .doctor-info-block {
        flex: 1;

        .doctor-info-data {
            .commonBorderPadding();
            padding: 2rem;
            margin: 2rem;
            border-radius: 2.5px;
            background: #FFFFFF;
            box-shadow: 0px 1.4px 4.9px 0px #00000019;

            .doctor-info {
                margin-bottom: 3rem;

                .doctor-title {
                    display: flex;

                    .adm-space-item {
                        width: 100%;
                        height: 100%;
                    }

                    .doctor-head {
                        @size: 6.4rem;
                        width: @size;
                        height: @size;
                        border-radius: @size;
                        overflow: hidden;
                        border: 2px solid @ColorBlue;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .doctor-theme-head {
                        border: 2px solid @ColorYellow  !important;
                    }

                    .patient-theme-head {
                        border: 2px solid @ColorBlue  !important;
                    }

                    .doctor-data {
                        font-size: 1.4rem;
                        margin-left: 2rem;

                        .doctor-name {
                            margin-top: .2rem;
                            font-weight: bold;
                            margin-left: 3rem;
                            font-size: @fontTitle;
                        }

                        .doctor-other-info {
                            color: @ColorMiddleGrey;
                            margin-top: .5rem;
                            display: -webkit-box;
                            overflow: hidden;
                        }
                    }
                }
            }

            .doctor-every-info {
                display: flex;
                font-size: 1.6rem;
                margin-top: .5rem;
                margin-bottom: .5rem;
                .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                    border-color: @ColorYellow;
                    background-color: @ColorYellow;
                }

                .doctor-info-title {
                    width: 10rem;
                    margin-right: 2rem;
                    font-weight: bold;
                    color: @ColorBlue;
                    margin-bottom: 1rem;
                }

                .doctor-theme-info-title {
                    // width: 50%;
                    color: @ColorYellow  !important;
                }

                .patient-theme-info-title {
                    color: @ColorBlue  !important;
                }

                .doctor-info-content {
                    flex: 1;
                    word-break: break-all;
                }
            }

            .doctor-every-info-double {
                align-items: center;
            }

            .doctor-long-info {
                font-size: 1.6rem;
                // margin-top: 2rem;
                .doctor-qrcode {
                    text-align: center;
                    padding: 1rem 0;
                }

                .doctor-long-info-title {
                    font-weight: bold;
                    color: @ColorBlue;
                }

                .doctor-theme-long-info-title {
                    color: @ColorYellow  !important;
                }

                .patient-theme-long-info-title {
                    color: @ColorBlue  !important;
                }

                .doctor-long-info-content {
                    margin-top: 1rem;
                    word-break: break-all;
                }
            }

            .doctor-info-content {
                font-size: 1.4rem;
            }
        }
    }

    .btn-submit {
        background-color: @ColorBlue;
        color: white;
        border-radius: 5px;
        font-size: 1.8rem;
        text-align: center;
        margin: 2rem 2rem 5rem;
        padding: 1.4rem 0;
        display: flex;
        flex-direction: column;
    }

    .doctor-theme-btn-submit {
        background-color: @ColorYellow  !important;
    }

    .patient-theme-btn-submit {
        background-color: @ColorBlue  !important;
    }
}
@ColorLightGrey: #8C8C8C;
@ColorMiddleGrey: #575757;
@ColorDarkGrey: rgba(142,142,147,.12);
@ColorBlack: #000;
@ColorBlackO1: rgba(0,0,0,.1);
@ColorBlackO3: rgba(0,0,0,.3);
@ColorC9: #C9C9C9;
@ColorF8: #F8F8F8;
@ColorE8: #E8E8E8;
@ColorRed:#f23030;
@ColorYellow:#FFAA00;
@ColorBlue:#2979FF;
@ColorLightYellow:#42351a;
@ColorWhite: #fff;
@ColorA: #aaa;
@YellowGradient: linear-gradient(180deg, #FFB92E 0%, #FFDD5B 100%);
@BlueGradient: linear-gradient(180deg, #2979FF 0%, #53B1FF 100%);
@ColorItemBg: #F1F1F2;

@font32: 3.2rem;
@font30: 3rem;
@font24: 2.4rem;
@font22: 2.2rem;
@fontSupLarge: 2rem;
@fontLarge: 1.8rem;
@fontTitle: 1.6rem;
@fontContent: 1.4rem;
@fontSubContent: 1.2rem;
@fontMin: 1rem;
@font15: 1.5rem;

@PageTitleHeight: 4.8rem;
@PageContentHeight: calc(100% - 4.8rem);

@borderPadding: 2rem;

.commonBorderPadding {
  padding: 0 2rem;
}
.commonLineMargin {
  margin: 2rem 0;
}

.commonLine {
  border-bottom: 1px solid @ColorBlackO3;
}

.commonLeftPose {
  width: 100%;
  border-radius: 1rem;
  border-left: 1.2rem solid @ColorYellow;
  background: @ColorDarkGrey;
  margin: 1rem 0;
  padding: .5rem;
  box-sizing: border-box;
}

.commonThemeBtn {
  height: 4.4rem;
  width: 98%;
  color: @ColorWhite !important;
  background: @ColorYellow !important;
  font-size: @fontLarge;
}
.commonScenodPageBlock {
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 4.8rem);
}

.bg-theme-doctor {
  background-color: @ColorYellow;
}
.bg-theme-patient {
  background-color: @ColorBlue;
}
.tx-color-doctor {
  color: @ColorYellow;
}
.tx-color-patient {
  color: @ColorBlue;
}
.border-color-doctor {
  border: 1px solid @ColorYellow;
}

.error-tx-color {
  color: @ColorRed;
}

.tab-txt-margin-top {
  margin-top: .5rem;
}
/* 样式重写 */
.adm-input .adm-input-element{
  border-radius: .4rem;
  padding: .5rem;
  border: 1px solid @ColorBlackO3;
}
// 搜索框隐藏border
.adm-search-bar {
  .adm-input .adm-input-element{
    border: none;
  }
}
.adm-input-disabled {
  opacity: 1;
} 

/*
* UI 设计规范样式 -fontSize  lineHeight and paragraph spacing for body is 1.4*fontSize
*/
@BodyMulti: 1.4;
.BodyFontAndLineHeightCommon(@fontSize) {
  font-size: @fontSize;
  line-height: @BodyMulti * @fontSize;
}
@LargeText: 20px;
@MediumText: 18px;
@NormalText: 16px;
@SmallText: 15px;
@SmallLessText: 13px;
@MiniText: 12px;

// ICON style
.IconCommonStyle {
  width: 24px;
  height: 24px;
}

/*
*normal button rule: padding left and right 5 * fontSize; top and bottom 1 * fontSize
*full-width button rule: lef and right determined by device; top and bottom 1 * fontSize
*/
.ButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.SecondaryButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  background: @ColorWhite;
  color: @ThemeColor;
  border-radius: 8px;
  border: 1px solid @ThemeColor;
}
.NormalLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .ButtonStyle(@LargeText, @ThemeColor);
}
.SecondaryLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .SecondaryButtonStyle(@LargeText, @ThemeColor);
}
.NormalMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .ButtonStyle(@MediumText, @ThemeColor);
}
.SecondaryMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .SecondaryButtonStyle(@MediumText, @ThemeColor);
}
.NormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .ButtonStyle(@NormalText, @ThemeColor);
}
.SecondaryNormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .SecondaryButtonStyle(@NormalText, @ThemeColor);
}

// full-width button
.FullWidthButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  padding: @fontSize*0.8 0;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.FullWidthSmallButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 4px;
}

.NormalFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .FullWidthButtonStyle(@NormalText, @ThemeColor);
}
.MiniFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MiniText);
  .FullWidthSmallButtonStyle(@MiniText, @ThemeColor);
}

.commonPadding {
  padding: 1.5rem 1rem;
}
.patient-home-content-block {
  padding: 1rem 2rem;
}

.home-title {
  font-size: @font22;
  font-weight: bold;
  margin: 0.5rem 0;
}

.match-consultant {
  background-color: @ColorE8;
  border-radius: 1rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
  font-size: @fontTitle;
  margin: 1rem 0;
  color: @ColorBlue;
  font-weight: bold;
}

.temp-image {
  width: 100%;
}

.list-item {
  --align-items: center;
  --prefix-padding-right: 1rem;
  background-color: @ColorItemBg;
  border-radius: .5rem;
  margin: 1.5rem 0;
  padding: .0 2rem;
}

.list-item-util {
  --align-items: center;
  --prefix-padding-right: 1rem;
  background-color: @ColorItemBg;
  border-radius: .5rem;
  margin-top: .5rem;
  padding: 0 2rem;
}

.border-item {
  border: 1.2px solid @ColorBlackO3;
  border-radius: 1.5rem;
  padding: .5rem;
  margin: 1rem 0;
}

.psychological-traning {
  margin: 1rem 0;

  .traning-image-content {
    padding: 1rem 0;

    .temp-image {
      width: 100%;
      border-radius: 1rem;
    }
  }
}

.mission-list {
  --align-items: center;
  --prefix-padding-right: 1rem;
  background-color: @ColorItemBg;
  border-radius: .5rem;
  margin-top: .5rem;
  padding: 0 2rem;
  height: 57px;
  font-size: 16px;
  font-weight: bold;
}

.my-mission {
  margin: 1rem 0;
  display: inline-block;

  .my-mission-title {
    display: inline-block;
  }

  .my-mission-list {
    display: inline-block;
  }
}

.func-item-title {
  font-size: @font15;
  font-weight: bold;
}

.func-item-desc {
  max-width: 16rem;
  margin-top: .8rem;
  font-size: @fontSubContent;
  color: #8c8c8c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.func-item-desc-not-consult {
  display: -webkit-box;
  max-width: 18rem;
  margin-top: .8rem;
  font-size: @fontSubContent;
  color: #8c8c8c;
  // overflow:hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
}

.function-article-title {
  display: flex;
  justify-content: space-between;
  margin: 1rem auto;

  .function-article-name {
    font-size: @font22;
    font-weight: bold;
  }

  .function-article-all {
    font-size: 1.4rem;
  }
}

// 我的咨询师
.myConsultant {
  border-radius: 16px;
  background: @ColorItemBg;
  overflow: auto;

  .myConsultant-img {
    position: absolute;
    right: 16px;
    top: 53px;
    z-index: 2;
    height: 140px;
  }

  .myConsultant-title {
    padding-top: 31px;
    padding-left: 22px;
    width: 120px;
    height: 27px;
    color: #000000;
    font-family: PingFang SC;
    font-weight: semibold;
    font-size: 24px;
    line-height: 26.4px;
    letter-spacing: 0px;
    text-align: center;
  }

  .myConsultant-description {
    padding-top: 4px;
    padding-left: 22px;
    width: 156px;
    height: 19px;
    color: #8C8C8C;
    font-family: PingFang SC;
    font-weight: regular;
    font-size: 13px;
    line-height: 18.2px;
    letter-spacing: 0px;
    text-align: center;
  }

  .myConsultant-button {
    margin-top: 48px;
    margin-bottom: 14px;
    margin-left: 19px;
    margin-right: 19px;
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
    background: #2979FF;
    text-align: center;

    .myConsultant-button-text {
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 15px;
      line-height: 19.6px;
      letter-spacing: 0px;
      display: inline-block;
      padding-top: 10px;
    }

    .out-of-sche-time-hint {
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 15px;
    }
  }

  .myConsultant-section {
    margin-top: 24px;
  }

  .myConsultant-section-content {
    margin-bottom: 18px;
    padding: 0 20px;
  }
}

// 舒小辅
.shuXiaoFu {
  height: 112px;
  border-radius: 16px;
  background: @ColorItemBg;
  margin-top: 14px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .shuXiaoFu-avatar {
    width: 45px;
    height: 45px;
  }

  .shuXiaoFu-dialog {
    height: 44px;
    min-width: 153px;
    padding: 12px;
    box-sizing: border-box;
    margin-left: 23px;
    border-radius: 23px 23px 23px 0px;
    background: #FFFFFF;

    .shuXiaoFu-text {
      color: #000000;
      font-family: PingFang SC;
      font-size: 15px;
    }
  }

}

// 小标题
.sectionTitle {
  margin-top: 24px;
  margin-bottom: 10px;
  color: #000000;
  font-family: PingFang HK;
  font-weight: bold;
  font-size: 24px;
}

.sectionTitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .section-all {
    font-size: 1.4rem
  }
}

// 心理训练营图片
.traning-content {
  padding: 0.5rem 0;

  .temp-image {
    width: 100%;
    border-radius: 1rem;
  }
}

// 我的任务
.myTask {
  height: 95px;
  border-radius: 16px;
  background: #FFCC00;
  overflow: auto;

  .myTask-content {
    padding-top: 25px;
    padding-left: 26px;

    .myTask-avatar-background {
      display: inline-block;
      width: 44px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 4rem;

      .myTask-avatar {
        margin: 0 auto;
        padding-top: 9px;
        display: block;
      }
    }

    .myTask-content-title {
      position: absolute;
      display: inline-block;
      margin-left: 16px;

      .content-title {
        color: #000000;
        font-family: PingFang SC;
        font-weight: semibold;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: 0px;
      }

      .content-description {
        color: #8C8C8C;
        font-family: PingFang SC;
        font-weight: regular;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0px;
      }
    }
  }
}

// 心理健康小工具
.psycholoTool {
  height: 66px;
  border-radius: 16px;
  background: @ColorItemBg;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .psycholoTool-content {
    padding-left: 26px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .psycholoTool-avatar-background {
      width: 44px;
      height: 44px;
      background: #FFFFFF;
      border-radius: 4rem;

      .psycholoTool-avatar {
        height: 22px;
        margin: 0 auto;
        padding-top: 9px;
        display: block;
      }
    }
  }

  .psycholoTool-content-title {
    margin-left: 16px;

    .psycholoTool-title {
      // color: #000000;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 18px;
      line-height: 25.2px;
      letter-spacing: 0px;
    }

    .psycholoTool-description {
      color: #8C8C8C;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0px;

    }

  }
}

// 心理健康知识封面
.psychologyKnowledge-banner {
  height: 120px;
  overflow: auto;
  border-radius: 16px;
  background: #2979FF;

  .psychologyKnowledge-banner-content {
    padding-top: 25px;
    padding-left: 27px;
    display: inline-block;

    .content-title {
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0px;
    }

    .content-description {
      margin-top: 1px;
      color: #DEEAFF;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 13px;
      line-height: 18.2px;
      letter-spacing: 0px;
    }
  }

  .psychologyKnowledge-banner-img {
    position: absolute;
    margin-top: 10px;
    margin-left: 45px;
    z-index: 2;
    height: 12%;
  }
}

// 心理健康知识文章
.articleWhole {
  padding-top: 3px;

  .article-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BCBBC1;
    padding: 5px 0;
  }

  .articleImg {
    width: 50px;
    height: 46px;
    border-radius: 8px;
  }

  .articleSection {
    flex: 1;
    margin-left: 15px;
    background: #FFFFFF;

    .article-title {
      margin-top: 14px;
      color: #000000;
      font-family: PingFang HK;
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: left;
    }

    .article-info {
      display: flex;
      justify-content: space-between;
    }

    .article-description {
      margin-top: 5px;
      color: #575757;
      font-family: PingFang HK;
      font-weight: regular;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: left;
    }

    .article-time {
      margin-top: 4px;
      color: #8C8C8C;
      font-family: PingFang HK;
      font-weight: regular;
      font-size: 10px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: left;

    }
  }
}

.psycholo-tools {
  border-radius: 16px;
  background: #F1F1F2;
  display: flex;
  // justify-content: space-around;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0 0 0;

  .psycholo-item {
    text-align: center;
    margin-bottom: 15px;
  }
}

.banner-img {
  height: 10%;
  width: 100%;
  border-radius: 16px;
}

.my-new-task {
  border-radius: 16px;
  background: #F1F1F2;
  display: flex;
  align-items: center;
  padding: 10px;

  .task-content {
    flex: 1;
    color: #000000;
    font-size: 15px;
    padding: 0 15px;
  }

  .task-more {
    color: #2979FF;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 15px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.video-block {
  position: fixed;
  top: 10rem;
  right: 0;
  width: 8rem;
  height: 12rem;
  border: .5rem solid gainsboro;
  border-radius: .5rem 0 0 .5rem;
  z-index: 99;
}

.full-screen {
  position: absolute;
  color: gainsboro;
  z-index: 999;
}

.video-frame {
  width: 100%;
  height: 100%;
}
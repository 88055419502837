@ColorLightGrey: #8C8C8C;
@ColorMiddleGrey: #575757;
@ColorDarkGrey: rgba(142,142,147,.12);
@ColorBlack: #000;
@ColorBlackO1: rgba(0,0,0,.1);
@ColorBlackO3: rgba(0,0,0,.3);
@ColorC9: #C9C9C9;
@ColorF8: #F8F8F8;
@ColorE8: #E8E8E8;
@ColorRed:#f23030;
@ColorYellow:#FFAA00;
@ColorBlue:#2979FF;
@ColorLightYellow:#42351a;
@ColorWhite: #fff;
@ColorA: #aaa;
@YellowGradient: linear-gradient(180deg, #FFB92E 0%, #FFDD5B 100%);
@BlueGradient: linear-gradient(180deg, #2979FF 0%, #53B1FF 100%);
@ColorItemBg: #F1F1F2;

@font32: 3.2rem;
@font30: 3rem;
@font24: 2.4rem;
@font22: 2.2rem;
@fontSupLarge: 2rem;
@fontLarge: 1.8rem;
@fontTitle: 1.6rem;
@fontContent: 1.4rem;
@fontSubContent: 1.2rem;
@fontMin: 1rem;
@font15: 1.5rem;

@PageTitleHeight: 4.8rem;
@PageContentHeight: calc(100% - 4.8rem);

@borderPadding: 2rem;

.commonBorderPadding {
  padding: 0 2rem;
}
.commonLineMargin {
  margin: 2rem 0;
}

.commonLine {
  border-bottom: 1px solid @ColorBlackO3;
}

.commonLeftPose {
  width: 100%;
  border-radius: 1rem;
  border-left: 1.2rem solid @ColorYellow;
  background: @ColorDarkGrey;
  margin: 1rem 0;
  padding: .5rem;
  box-sizing: border-box;
}

.commonThemeBtn {
  height: 4.4rem;
  width: 98%;
  color: @ColorWhite !important;
  background: @ColorYellow !important;
  font-size: @fontLarge;
}
.commonScenodPageBlock {
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 4.8rem);
}

.bg-theme-doctor {
  background-color: @ColorYellow;
}
.bg-theme-patient {
  background-color: @ColorBlue;
}
.tx-color-doctor {
  color: @ColorYellow;
}
.tx-color-patient {
  color: @ColorBlue;
}
.border-color-doctor {
  border: 1px solid @ColorYellow;
}

.error-tx-color {
  color: @ColorRed;
}

.tab-txt-margin-top {
  margin-top: .5rem;
}
/* 样式重写 */
.adm-input .adm-input-element{
  border-radius: .4rem;
  padding: .5rem;
  border: 1px solid @ColorBlackO3;
}
// 搜索框隐藏border
.adm-search-bar {
  .adm-input .adm-input-element{
    border: none;
  }
}
.adm-input-disabled {
  opacity: 1;
} 

/*
* UI 设计规范样式 -fontSize  lineHeight and paragraph spacing for body is 1.4*fontSize
*/
@BodyMulti: 1.4;
.BodyFontAndLineHeightCommon(@fontSize) {
  font-size: @fontSize;
  line-height: @BodyMulti * @fontSize;
}
@LargeText: 20px;
@MediumText: 18px;
@NormalText: 16px;
@SmallText: 15px;
@SmallLessText: 13px;
@MiniText: 12px;

// ICON style
.IconCommonStyle {
  width: 24px;
  height: 24px;
}

/*
*normal button rule: padding left and right 5 * fontSize; top and bottom 1 * fontSize
*full-width button rule: lef and right determined by device; top and bottom 1 * fontSize
*/
.ButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.SecondaryButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  background: @ColorWhite;
  color: @ThemeColor;
  border-radius: 8px;
  border: 1px solid @ThemeColor;
}
.NormalLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .ButtonStyle(@LargeText, @ThemeColor);
}
.SecondaryLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .SecondaryButtonStyle(@LargeText, @ThemeColor);
}
.NormalMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .ButtonStyle(@MediumText, @ThemeColor);
}
.SecondaryMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .SecondaryButtonStyle(@MediumText, @ThemeColor);
}
.NormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .ButtonStyle(@NormalText, @ThemeColor);
}
.SecondaryNormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .SecondaryButtonStyle(@NormalText, @ThemeColor);
}

// full-width button
.FullWidthButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  padding: @fontSize*0.8 0;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.FullWidthSmallButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 4px;
}

.NormalFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .FullWidthButtonStyle(@NormalText, @ThemeColor);
}
.MiniFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MiniText);
  .FullWidthSmallButtonStyle(@MiniText, @ThemeColor);
}

.commonPadding {
  padding: 1.5rem 1rem;
}
.detail-info-content {
  .commonPadding();

  .consultant-info {
    display: flex;

    .consultant-head {
      @size: 5rem;
      width: @size;
      height: @size;
      border-radius: @size;
      overflow: hidden;
      border-width: 1.5px;
      border-style: solid;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .consultant-data {
      margin-left: 1.3rem;

      .consultant-name {
        font-size: 1.7rem;
        font-weight: bold;
      }

      .consultant-other-info {
        margin-top: .5rem;
        font-size: 1.4rem;
      }
    }
  }

  .form-item {
    padding: 1rem 0;
    margin: 1rem 0;
    border-bottom: 1px solid @ColorC9;

    .form-item-title {
      display: flex;
      justify-content: space-between;
      font-size: 1.7rem;
      font-weight: bold;
      margin-bottom: 1rem;

      .form-item-text {
        color: #000000;
        font-family: PingFang HK;
        font-weight: semibold;
        font-size: 24px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
      }

      .show-all {
        color: @ColorYellow;
        font-size: 14px;
      }

      .doctor-show-all {
        color: @ColorYellow  !important;
      }

      .patient-show-all {
        color: @ColorBlue  !important;
      }
    }

    .data-item {
      // display: flex;
      // justify-content: space-between;
      display: inline-block;
      margin-top: 1.2rem;
      border-radius: 16px;
      background: @ColorItemBg;
      height: 60px;
      width: 100%;

      .item-section {
        // 外层盒子高度已经定了，设定line-height和高度一致就会垂直居中
        display: inline-block;
        line-height: 60px;
        padding-left: 10px;

        .item-info {
          display: inline-block;
          font-size: 1.4rem;

          .span-time {
            color: #8C8C8C;
            font-family: PingFang HK;
            font-weight: regular;
            font-size: 15px;
          }

          .span-title {
            padding-left: 20px;
            color: #000000;
            font-family: PingFang HK;
            font-weight: regular;
            font-size: 15px;
          }
        }

        .item-icon {
          display: inline-block;
          position: absolute;
          right: 30px;
          color: @ColorYellow;
          font-weight: bold;
        }

        .doctor-item-icon {
          color: @ColorYellow  !important;
        }

        .patient-item-icon {
          color: @ColorBlue  !important;
        }
      }

    }

    .sub-buttons {
      display: flex;
      margin-bottom: 2rem;

      .sub-button {
        margin-right: 1rem;
        border-radius: 16px;
        background: @ColorItemBg;
        width: 62px;
        height: 73px;

        .sub-button-icon {
          width: fit-content;
          margin: 10px auto;
          padding: 0.5rem;
          color: white;
          border-radius: 0.5rem;
          background-color: @ColorYellow;
        }

        .sub-button-text {
          text-align: center;
        }
      }
    }
  }

  .comment-text {
    background: @ColorItemBg  !important;
  }

  .consulting-comment {
    width: 90%;
    height: 10rem;
    overflow: auto;
    padding: 2rem 1.5rem;
    background: #FFF9E4;
    margin: 1rem auto;
    margin-bottom: 0;
    border-radius: 1.6rem;
    font-size: @fontContent;
    background: @ColorItemBg;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
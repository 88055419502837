@ColorLightGrey: #8C8C8C;
@ColorMiddleGrey: #575757;
@ColorDarkGrey: rgba(142,142,147,.12);
@ColorBlack: #000;
@ColorBlackO1: rgba(0,0,0,.1);
@ColorBlackO3: rgba(0,0,0,.3);
@ColorC9: #C9C9C9;
@ColorF8: #F8F8F8;
@ColorE8: #E8E8E8;
@ColorRed:#f23030;
@ColorYellow:#FFAA00;
@ColorBlue:#2979FF;
@ColorLightYellow:#42351a;
@ColorWhite: #fff;
@ColorA: #aaa;
@YellowGradient: linear-gradient(180deg, #FFB92E 0%, #FFDD5B 100%);
@BlueGradient: linear-gradient(180deg, #2979FF 0%, #53B1FF 100%);
@ColorItemBg: #F1F1F2;

@font32: 3.2rem;
@font30: 3rem;
@font24: 2.4rem;
@font22: 2.2rem;
@fontSupLarge: 2rem;
@fontLarge: 1.8rem;
@fontTitle: 1.6rem;
@fontContent: 1.4rem;
@fontSubContent: 1.2rem;
@fontMin: 1rem;
@font15: 1.5rem;

@PageTitleHeight: 4.8rem;
@PageContentHeight: calc(100% - 4.8rem);

@borderPadding: 2rem;

.commonBorderPadding {
  padding: 0 2rem;
}
.commonLineMargin {
  margin: 2rem 0;
}

.commonLine {
  border-bottom: 1px solid @ColorBlackO3;
}

.commonLeftPose {
  width: 100%;
  border-radius: 1rem;
  border-left: 1.2rem solid @ColorYellow;
  background: @ColorDarkGrey;
  margin: 1rem 0;
  padding: .5rem;
  box-sizing: border-box;
}

.commonThemeBtn {
  height: 4.4rem;
  width: 98%;
  color: @ColorWhite !important;
  background: @ColorYellow !important;
  font-size: @fontLarge;
}
.commonScenodPageBlock {
  padding: 2rem;
  width: 100%;
  height: calc(100vh - 4.8rem);
}

.bg-theme-doctor {
  background-color: @ColorYellow;
}
.bg-theme-patient {
  background-color: @ColorBlue;
}
.tx-color-doctor {
  color: @ColorYellow;
}
.tx-color-patient {
  color: @ColorBlue;
}
.border-color-doctor {
  border: 1px solid @ColorYellow;
}

.error-tx-color {
  color: @ColorRed;
}

.tab-txt-margin-top {
  margin-top: .5rem;
}
/* 样式重写 */
.adm-input .adm-input-element{
  border-radius: .4rem;
  padding: .5rem;
  border: 1px solid @ColorBlackO3;
}
// 搜索框隐藏border
.adm-search-bar {
  .adm-input .adm-input-element{
    border: none;
  }
}
.adm-input-disabled {
  opacity: 1;
} 

/*
* UI 设计规范样式 -fontSize  lineHeight and paragraph spacing for body is 1.4*fontSize
*/
@BodyMulti: 1.4;
.BodyFontAndLineHeightCommon(@fontSize) {
  font-size: @fontSize;
  line-height: @BodyMulti * @fontSize;
}
@LargeText: 20px;
@MediumText: 18px;
@NormalText: 16px;
@SmallText: 15px;
@SmallLessText: 13px;
@MiniText: 12px;

// ICON style
.IconCommonStyle {
  width: 24px;
  height: 24px;
}

/*
*normal button rule: padding left and right 5 * fontSize; top and bottom 1 * fontSize
*full-width button rule: lef and right determined by device; top and bottom 1 * fontSize
*/
.ButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.SecondaryButtonStyle(@fontSize, @ThemeColor) {
  text-align: center;
  padding: @fontSize 5 * @fontSize;
  background: @ColorWhite;
  color: @ThemeColor;
  border-radius: 8px;
  border: 1px solid @ThemeColor;
}
.NormalLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .ButtonStyle(@LargeText, @ThemeColor);
}
.SecondaryLargeButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@LargeText);
  .SecondaryButtonStyle(@LargeText, @ThemeColor);
}
.NormalMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .ButtonStyle(@MediumText, @ThemeColor);
}
.SecondaryMediumButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MediumText);
  .SecondaryButtonStyle(@MediumText, @ThemeColor);
}
.NormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .ButtonStyle(@NormalText, @ThemeColor);
}
.SecondaryNormalButton (@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .SecondaryButtonStyle(@NormalText, @ThemeColor);
}

// full-width button
.FullWidthButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  padding: @fontSize*0.8 0;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 8px;
}
.FullWidthSmallButtonStyle(@fontSize, @ThemeColor) {
  width: 98%;
  text-align: center;
  color: @ColorWhite;
  background: @ThemeColor;
  border-radius: 4px;
}

.NormalFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@NormalText);
  .FullWidthButtonStyle(@NormalText, @ThemeColor);
}
.MiniFullWidthButton(@ThemeColor) {
  .BodyFontAndLineHeightCommon(@MiniText);
  .FullWidthSmallButtonStyle(@MiniText, @ThemeColor);
}

.commonPadding {
  padding: 1.5rem 1rem;
}
.calendar-page {
  @ColorFree: blue;
  @ColorBusy: gainsboro;
  @ColorAppointed: sandybrown;
  @ColorScheduling: red;
  height: @PageContentHeight;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .fc-media-screen {
    height: 100%;
  }

  .fc-event-time {
    display: none;
  }

  .calendar-date-picker {
    width: 100%;
    padding: 1rem;
    display: flex;
    width: max-content;

    .calendar-date-value {
      border: 1px solid gainsboro;
      box-shadow: 0 0 1rem gainsboro;
      width: max-content;
      display: flex;
      padding: 1rem;
      font-size: 1.6rem;

      .calendar-date-icon {
        color: @ColorBlue;
        margin-left: .5rem;
      }
    }
  }

  .calendar-change-page {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem 1.25rem;
    z-index: 999;
    width: 85%;
    height: 27px;

    .calendar-change-button {
      // border: 1px solid gainsboro;
      padding: .5rem;
      // color: #2979FF;
    }

    .calendar-change-block {
      flex: 1;
    }
  }

  // 医生和患者的主题色
  .color-patient {
    color: @ColorBlue;
  }
  .color-doctor {
    color: @ColorYellow;
  }

  .calendar-block {
    width: 96%;
    flex: 1;
    margin-top: -4rem;

    .fc-theme-standard td,
    .fc-theme-standard th,
    .fc-col-header th {
      border: none !important;
      border-right: 1px solid #ddd !important;
    }

    .eventColorFree {
      background-color: @ColorFree  !important;
      border-color: @ColorFree  !important;
      // color: red !important;
    }

    .eventColorBusy {
      background-color: @ColorBusy  !important;
      border-color: @ColorBusy  !important;
      // color: red !important;
    }

    .eventColorAppointed {
      background-color: @ColorAppointed  !important;
      border-color: @ColorAppointed  !important;
      // color: red !important;
    }

    .eventColorScheduling {
      background-color: @ColorScheduling  !important;
      border-color: @ColorScheduling  !important;
      // color: red !important;
    }
  }

  .appointment-block {
    margin: 1rem;
    padding: .5rem;
    border-left: 1rem solid gainsboro;
    border-bottom: 1px solid gainsboro;
    display: flex;
    justify-content: space-between;
    width: 88%;

    .appointment-time {
      display: flex;
      align-items: center;

      .appoint-staff-name {
        margin-left: 2rem;
        font-weight: bold;
        font-size: @fontContent;
        color: @ColorYellow;
      }
    }

    .appointment-button {
      height: 1.5rem;
      line-height: 1.5rem;
      padding: .5rem 1.5rem;
      border: 1px solid @ColorBlack;
      border-radius: 4px;
      color: @ColorBlack;
    }
  }

  .appointment-block-eventColorFree {
    border-left: 1rem solid @ColorFree  !important;
  }

  .appointment-block-eventColorBusy {
    border-left: 1rem solid @ColorBusy  !important;
  }

  .appointment-block-eventColorAppointed {
    border-left: 1rem solid @ColorAppointed  !important;
  }

  .appointment-block-eventColorScheduling {
    border-left: 1rem solid @ColorScheduling  !important;
  }

  .date-formate-line1 {
    color: #000000;
    font-size: 14px;
  }

  .date-formate-line2 {
    color: #575757;
    font-size: 12px;
  }
}

:root {
  .fc-scrollgrid-shrink-cushion {
    color: var(--roleStyle);
  }

  /* 日历头颜色 */
  .fc-col-header-cell-cushion {
    color: black !important;
    font-size: 13px;
  }
}

.modal-title {
  font-size: @fontTitle;
  font-weight: bold;
  margin-bottom: 1rem;
}

.agree-rule {
  margin-top: 1rem;
  font-size: @fontContent;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;

  .modal-button {
    font-size: @fontTitle;
    width: 30%;
    color: white;
    text-align: center;
    padding: .5rem 0;
    border-radius: .5rem;
  }

  .modal-cancel {
    background-color: gray;
  }

  .modal-confirm {
    background-color: @ColorBlue;
  }
}

/*
  重写样式
*/
.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-toolbar-title {
  font-size: @fontTitle  !important;
}

.fc-event-title-container {
  display: flex;
  align-items: center;
}